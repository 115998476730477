<template>
  <div class="filalis-map-wrapper">
    <shop-types-list
      v-if="isShowShopTypes"
      :shopTypesList="shopTypesList"
      @select-shop-type="selectShopsByType"
      @change-shops-view="changeView"
      :isMapShow="isMapShow"
      :isShowTitle="isShowTitle"
      :isShowList="isShowList"
      :key="selectedCity.id"
    />
    <div v-show="shpsListWithoutNullGeo" class="container">
      <shops-list
        :shops-list="shpsListWithoutNullGeo"
        @show-in-map="showInMap"
        @search="searchInList"
        :shopTypesList="shopTypesList"
        :class="{ 'is-list-active': !isMapShow }"
        @filter-changed="filterChanged"
        :isShowDetails="isShowDetails"
        @openDetails="isShowDetails = true"
        @closeDetails="isShowDetails = false"
        :isTermReset="isTermReset"
      />
    </div>
    <section
      v-show="shpsListWithoutNullGeo && initCoord && isMapShow"
      class="map"
    >
      <div id="map">
        <yandex-map
          v-if="shpsListWithoutNullGeo"
          :coords="initCoord"
          :zoom="zoom"
          :cluster-options="clusterOptions"
          @map-was-initialized="initMap"
          :controls="[]"
          :key="shpsListWithoutNullGeo.length"
        >
          <ymap-marker
            v-for="shop in shpsListWithoutNullGeo"
            :coords="getShopCoords(shop)"
            :key="shop.id"
            marker-id="1"
            cluster-name="1"
            :icon="getMarkerIcon(shop)"
            @click="selectShop(shop)"
          />
        </yandex-map>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
const MAP_MARKER_HYPER = require("@/assets/icons/hyper.svg");
const MAP_CLUSTER_ICON = require("@/assets/icons/cluster.svg");
import { getShopTypes, getShopsByFilters } from "@/api/shop";

// import { loadYmap } from "vue-yandex-maps";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "ShopsMap",
  props: {
    isShowShopTypes: {
      type: Boolean,
      default: false,
    },
    shopsList: {
      type: Array,
      required: true,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    isShowList: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    yandexMap,
    ymapMarker,
    ShopsList: () => import("@/components/maps/ShopsList.vue"),
    ShopTypesList: () => import("@/components/maps/ShopTypesList.vue"),
  },

  data: () => ({
    isTermReset: false,
    shpsListWithoutNullGeo: null, // some shops have geo field null
    initCoord: null,
    isShowDetails: false,
    zoom: 13,
    isMapShow: true,
    initCoordsForMegapolis: {
      2: [43.245569, 76.871381],
      3: [51.133582, 71.360522],
      10: [43.296232, 68.240386],
    },
    clusterOptions: {
      1: {
        clusterIcons: [
          {
            href: MAP_CLUSTER_ICON,
            size: [60, 60],
            offset: [-10, -5],
            contentSize: 100,
          },
        ],
        preset: "islands#invertedVioletClusterIcons",
        gridSize: 150,
        clusterDisableClickZoom: false,
        clusterOpenBalloonOnClick: false,
        clusterHideIconOnBalloonOpen: false,
        clusterBalloonLayout: "<span></span>",
      },
    },
    yandexMap: null,
    searchResults: null,
    selectedShopType: "",
    shopTypesList: [{ id: 0, attributes: { name: "" } }],
  }),
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    shopsList: {
      handler() {
        this.initShops();
      },
    },
  },
  created() {
    this.shopTypesList[0].attributes.name = this.$t("allShops");
    getShopTypes().then((shopTypes) => {
      this.shopTypesList = [
        ...this.shopTypesList,
        ...shopTypes.sort((a, b) =>
          a.attributes.order > b.attributes.order ? 1 : -1
        ),
      ];
    });
  },
  async mounted() {
    this.initShops();
  },
  methods: {
    initMap(e) {
      this.yandexMap = e;
    },

    initShops() {
      this.shpsListWithoutNullGeo = null;
      this.shpsListWithoutNullGeo = this.shopsList.filter(
        (s) => s.attributes.geo != null
      );
      this.searchResults = this.shpsListWithoutNullGeo;

      if (
        this.selectedCity?.id === 2 ||
        this.selectedCity?.id === 10 ||
        (this.selectedCity?.id === 3 && this.searchResults === null)
      ) {
        this.initCoord = this.initCoordsForMegapolis[this.selectedCity?.id];
      } else {
        const shop = this.shpsListWithoutNullGeo.find(
          (s) => s.attributes.geo !== null
        );
        this.initCoord = [
          shop.attributes.geo.latitude,
          shop.attributes.geo.longitude,
        ];
      }
    },

    filterChanged(fitlers) {
      this.shpsListWithoutNullGeo = null;
      getShopsByFilters(this.selectedCity?.id, fitlers).then((shops) => {
        this.shpsListWithoutNullGeo = shops;
      });
    },

    loadedShopTypes(list) {
      this.shopTypesList = list;
    },

    changeView(type) {
      this.isMapShow = type === "map" ? true : false;

      this.searchResults = null;
    },

    showInMap(geo) {
      this.isMapShow = true;
      this.isShowDetails = false;
      this.isTermReset = true;
      if (geo?.latitude && geo.longitude) {
        this.initCoord = [geo?.latitude, geo.longitude];
        this.yandexMap.setCenter([geo?.latitude, geo.longitude], 18);
        // this.yandexMap.setZoom(18);
      }
      this.searchResults = null;
    },

    searchInList(term) {
      term = term.toLowerCase();
      this.shpsListWithoutNullGeo = null;
      this.searchResults = null;
      this.isTermReset = false;
      if (!term) {
        // this.searchResults = this.shopsList;
        this.shpsListWithoutNullGeo = this.shopsList;
        return;
      }
      this.searchResults = this.shopsList.filter((s) => {
        return (
          s.attributes.name.toLowerCase().includes(term) ||
          s.attributes.address.toLowerCase().includes(term) ||
          s.attributes.address.toLowerCase().indexOf(term) !== -1 ||
          s.attributes.name.toLowerCase().indexOf(term) !== -1
        );
      });
      this.shpsListWithoutNullGeo = this.searchResults;
      // this.yandexMap.setCenter(
      //   [
      //     this.shpsListWithoutNullGeo[0].attributes.geo.latitude,
      //     this.shpsListWithoutNullGeo[0].attributes.geo.longitude,
      //   ],
      //   11
      // );
      if (this.shpsListWithoutNullGeo?.length) {
        this.initCoord = [
          this.shpsListWithoutNullGeo[0].attributes.geo.latitude,
          this.shpsListWithoutNullGeo[0].attributes.geo.longitude,
        ];
      }
      // this.shopsList = null;
    },

    selectShopsByType(shopType) {
      // this.yandexMap.setZoom(8);
      this.zoom = 9;
      // this.shopsList = [];
      console.log("SHOPS", this.shopType);

      if (shopType?.id === 0) {
        getShopsByFilters(this.selectedCity?.id).then((shops) => {
          this.shpsListWithoutNullGeo = shops;
          // this.shopsList = shops;
        });
        return;
      }
      getShopsByFilters(this.selectedCity?.id, {
        shopType: [
          shopType.id,
          shopType?.attributes?.localizations?.data[0].id,
        ],
      }).then((shops) => {
        this.shpsListWithoutNullGeo = shops;
        // this.shopsList = shops;
      });
    },

    selectShop(shop) {
      window.open(
        `https://magnum.kz/${this.$i18n.locale}/shops/${shop.id}`,
        "_blank"
      );
    },

    getShopCoords(shop) {
      return [shop.attributes.geo?.latitude, shop.attributes.geo?.longitude];
    },

    getMarkerIcon(shop) {
      const iconUrl =
        shop.attributes?.shop_type?.data?.attributes?.logo?.data?.attributes
          ?.url;
      return {
        layout: "default#image",
        imageHref: iconUrl
          ? process.env.VUE_APP_API_URL + iconUrl
          : MAP_MARKER_HYPER,
        imageSize: [60, 60],
        imageOffset: [-10, -5],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ymap-container {
  height: 630px;
}
.filalis-map-wrapper {
  position: relative;
  .map-card {
    position: absolute;
    z-index: 99;
    bottom: 20px;
  }
}
</style>
